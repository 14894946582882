import React, { FC } from 'react';
import PropTypes from 'prop-types';
import logo_douro from '../assets/logos/logo_douro.png';

interface ILogoProps {
	width?: number;
	height?: number;
}
const Logo: FC<ILogoProps> = ({ width, height }) => {

	const WIDTH = height !== 854 && !!height ? height * (2155 / 854) : width
	const HEIGHT =width !== 2155 && !!width ? width * (854 / 2155) : height

	return (
		<img src={logo_douro} alt='Logo' width={WIDTH}  />
	);
	// return (
	// 	<svg
	// 		width={height !== 854 && !!height ? height * (2155 / 854) : width}
	// 		height={width !== 2155 && !!width ? width * (854 / 2155) : height}
	// 		viewBox='0 0 2155 854'
	// 		fill='none'
	// 		xmlns='http://www.w3.org/2000/svg'>
	// 		<path fill="rgba(255,255,255,0)" opacity="1.000000" stroke="none" 
	// 			d="
	// 		M332.000000,513.000000 
	// 			C221.360214,513.000000 111.220421,513.000000 1.040315,513.000000 
	// 			C1.040315,342.401855 1.040315,171.803696 1.040315,1.102769 
	// 			C171.556458,1.102769 342.113007,1.102769 512.834778,1.102769 
	// 			C512.834778,171.666534 512.834778,342.333252 512.834778,513.000000 
	// 			C452.803680,513.000000 392.651825,513.000000 332.000000,513.000000 
	// 		M125.249123,386.170044 
	// 			C126.052498,394.177551 126.855881,402.185089 127.723969,410.837555 
	// 			C130.228622,409.444458 131.976105,408.522614 133.675659,407.519562 
	// 			C155.477615,394.653107 177.524673,382.175690 198.946152,368.703705 
	// 			C207.576401,363.276154 216.292252,361.270905 226.155472,361.195862 
	// 			C254.142914,360.983032 282.126862,360.340027 310.113831,359.999664 
	// 			C313.208923,359.962006 315.256409,359.148621 317.215271,356.580963 
	// 			C328.226898,342.147064 339.554962,327.954193 350.532043,313.494629 
	// 			C352.751068,310.571655 354.989502,309.667786 358.495544,310.003815 
	// 			C374.235657,311.512543 389.988098,312.918121 405.757721,314.062927 
	// 			C407.938202,314.221222 410.728638,313.298767 412.409515,311.889832 
	// 			C430.024109,297.125366 447.438507,282.122375 465.001343,267.295593 
	// 			C467.263275,265.386047 468.246979,263.509888 467.888824,260.493652 
	// 			C465.436035,239.837921 463.247314,219.149933 460.630402,198.515823 
	// 			C460.304718,195.947617 458.545013,192.829391 456.466370,191.323639 
	// 			C416.405884,162.304489 376.229279,133.444855 335.941162,104.742546 
	// 			C333.635193,103.099709 330.130402,102.282906 327.251617,102.428452 
	// 			C278.860596,104.875000 230.480377,107.534958 182.097855,110.149841 
	// 			C169.129135,110.850754 156.166321,111.711655 143.189301,112.181358 
	// 			C138.675293,112.344749 136.109360,114.434494 133.668854,117.964836 
	// 			C105.633560,158.519638 77.509224,199.013168 49.266159,239.423492 
	// 			C46.718197,243.069138 45.762432,246.630859 45.970158,251.000488 
	// 			C47.148335,275.784241 48.244953,300.573700 49.047989,325.371613 
	// 			C49.185326,329.612640 50.785774,331.430573 54.475704,332.975311 
	// 			C75.816864,341.909668 97.015228,351.185516 118.368263,360.090790 
	// 			C122.169098,361.675964 123.619942,363.982300 123.823792,367.823212 
	// 			C124.132515,373.639984 124.711807,379.442413 125.249123,386.170044 
	// 		z"/>
	// 		<path fill="#F95F79" opacity="1.000000" stroke="none" 
	// 			d="
	// 		M125.212631,385.710480 
	// 			C124.711807,379.442413 124.132515,373.639984 123.823792,367.823212 
	// 			C123.619942,363.982300 122.169098,361.675964 118.368263,360.090790 
	// 			C97.015228,351.185516 75.816864,341.909668 54.475704,332.975311 
	// 			C50.785774,331.430573 49.185326,329.612640 49.047989,325.371613 
	// 			C48.244953,300.573700 47.148335,275.784241 45.970158,251.000488 
	// 			C45.762432,246.630859 46.718197,243.069138 49.266159,239.423492 
	// 			C77.509224,199.013168 105.633560,158.519638 133.668854,117.964836 
	// 			C136.109360,114.434494 138.675293,112.344749 143.189301,112.181358 
	// 			C156.166321,111.711655 169.129135,110.850754 182.097855,110.149841 
	// 			C230.480377,107.534958 278.860596,104.875000 327.251617,102.428452 
	// 			C330.130402,102.282906 333.635193,103.099709 335.941162,104.742546 
	// 			C376.229279,133.444855 416.405884,162.304489 456.466370,191.323639 
	// 			C458.545013,192.829391 460.304718,195.947617 460.630402,198.515823 
	// 			C463.247314,219.149933 465.436035,239.837921 467.888824,260.493652 
	// 			C468.246979,263.509888 467.263275,265.386047 465.001343,267.295593 
	// 			C447.438507,282.122375 430.024109,297.125366 412.409515,311.889832 
	// 			C410.728638,313.298767 407.938202,314.221222 405.757721,314.062927 
	// 			C389.988098,312.918121 374.235657,311.512543 358.495544,310.003815 
	// 			C354.989502,309.667786 352.751068,310.571655 350.532043,313.494629 
	// 			C339.554962,327.954193 328.226898,342.147064 317.215271,356.580963 
	// 			C315.256409,359.148621 313.208923,359.962006 310.113831,359.999664 
	// 			C282.126862,360.340027 254.142914,360.983032 226.155472,361.195862 
	// 			C216.292252,361.270905 207.576401,363.276154 198.946152,368.703705 
	// 			C177.524673,382.175690 155.477615,394.653107 133.675659,407.519562 
	// 			C131.976105,408.522614 130.228622,409.444458 127.723969,410.837555 
	// 			C126.855881,402.185089 126.052498,394.177551 125.212631,385.710480 
	// 		M323.536438,246.901642 
	// 			C332.724915,246.652390 339.396118,242.460449 343.048004,233.987411 
	// 			C346.483765,226.015762 344.950165,218.562775 339.326294,212.222641 
	// 			C333.666718,205.842255 326.359863,203.747284 318.079163,206.089478 
	// 			C308.624176,208.763824 302.427124,217.853027 303.119232,227.661896 
	// 			C303.834900,237.804947 311.475220,245.315491 323.536438,246.901642 
	// 		M181.463196,207.753952 
	// 			C180.103973,208.702667 178.646240,209.537949 177.401932,210.618927 
	// 			C170.544281,216.576523 168.562271,226.877792 172.608337,235.157883 
	// 			C176.645050,243.418793 185.713791,248.206070 194.406143,246.621521 
	// 			C203.072388,245.041748 208.941681,239.985321 211.311890,231.604080 
	// 			C213.631088,223.403168 211.383270,216.013367 204.904953,210.406281 
	// 			C198.102829,204.518890 190.357590,203.650986 181.463196,207.753952 
	// 		z"/>
	// 		<path fill="rgba(255,253,253,0)" opacity="1.000000" stroke="none" 
	// 			d="
	// 		M323.108582,246.896393 
	// 			C311.475220,245.315491 303.834900,237.804947 303.119232,227.661896 
	// 			C302.427124,217.853027 308.624176,208.763824 318.079163,206.089478 
	// 			C326.359863,203.747284 333.666718,205.842255 339.326294,212.222641 
	// 			C344.950165,218.562775 346.483765,226.015762 343.048004,233.987411 
	// 			C339.396118,242.460449 332.724915,246.652390 323.108582,246.896393 
	// 		z"/>
	// 		<path fill="rgba(255,253,253,0)" opacity="1.000000" stroke="none" 
	// 			d="
	// 		M181.802399,207.576630 
	// 			C190.357590,203.650986 198.102829,204.518890 204.904953,210.406281 
	// 			C211.383270,216.013367 213.631088,223.403168 211.311890,231.604080 
	// 			C208.941681,239.985321 203.072388,245.041748 194.406143,246.621521 
	// 			C185.713791,248.206070 176.645050,243.418793 172.608337,235.157883 
	// 			C168.562271,226.877792 170.544281,216.576523 177.401932,210.618927 
	// 			C178.646240,209.537949 180.103973,208.702667 181.802399,207.576630 
	// 		z"/>
	// 	</svg>
	// );
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
