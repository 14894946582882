import { decode } from "punycode";
import { de, tr } from "date-fns/locale";

export function test() {
	return null;
}

// export function getOS() {
// 	const { userAgent } = window.navigator;
// 	const { platform } = window.navigator;
// 	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
// 	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
// 	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
// 	const os = null;

// 	if (macosPlatforms.indexOf(platform) !== -1) {
// 		os = 'MacOS';
// 	} else if (iosPlatforms.indexOf(platform) !== -1) {
// 		os = 'iOS';
// 	} else if (windowsPlatforms.indexOf(platform) !== -1) {
// 		os = 'Windows';
// 	} else if (/Android/.test(userAgent)) {
// 		os = 'Android';
// 	} else if (!os && /Linux/.test(platform)) {
// 		os = 'Linux';
// 	}

// 	// @ts-ignore
// 	document.documentElement.setAttribute('os', os);
// 	return os;
// }

export function getOS() {
	if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
	  return null;
	}
  
	const { userAgent, platform } = window.navigator;
	if (typeof userAgent === 'undefined' || typeof platform === 'undefined') {
	  return null;
	}
  
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;
  
	if (macosPlatforms.indexOf(platform) !== -1) {
	  os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
	  os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
	  os = 'Windows';
	} else if (/Android/.test(userAgent)) {
	  os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
	  os = 'Linux';
	}
  
	if (os) {
	  // @ts-ignore
	  document.documentElement.setAttribute('os', os);
	}
	
	return os;
}
  

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	// @ts-ignore
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const getDataSession = (sessionDecrypted: any) => {
	try {
		
		if(sessionDecrypted) {
			try {
				const ip:any = sessionDecrypted.split("-").slice(-1)[0];
				const machine:string = sessionDecrypted.split("-")[0];
				return { ip, machine };
			} catch (error) {
				console.error("Erro session utf8");
			}
		}
	} catch (error) {
		console.error("Erro decrypt session");
	}
	return { ip: null, machine: null };
}

export const decrypt = (transitmessage:any, pass:any, CryptoJS:any) => {
	const keySize = 256;
	const iterations = 100;
	
	const salt = CryptoJS.enc.Hex.parse(transitmessage.substr(0, 32));
	const iv = CryptoJS.enc.Hex.parse(transitmessage.substr(32, 32))
	const encrypted = transitmessage.substring(64);
	
	const key = CryptoJS.PBKDF2(pass, salt, {
		keySize: keySize/32,
		iterations
	  });
  
	const decrypted = CryptoJS.AES.decrypt(encrypted, key, { 
	  iv, 
	  padding: CryptoJS.pad.Pkcs7,
	  mode: CryptoJS.mode.CBC,
	  hasher: CryptoJS.algo.SHA256
	})
	return decrypted;
}
  
export const encrypt = (msg: any, pass: any) => {
	const keySize = 256;
	const ivSize = 128;
	const iterations = 100;
	const salt = CryptoJS.lib.WordArray.random(128/8);

	const key = CryptoJS.PBKDF2(pass, salt, {
		keySize: keySize/32,
		iterations
	});

	const iv = CryptoJS.lib.WordArray.random(ivSize/8);

	const encrypted = CryptoJS.AES.encrypt(msg, key, { 
		iv, 
		padding: CryptoJS.pad.Pkcs7,
		mode: CryptoJS.mode.CBC,
		hasher: CryptoJS.algo.SHA256
	});

	// salt, iv will be hex 32 in length
	// append them to the ciphertext for use  in decryption
	const transitmessage = salt.toString()+ iv.toString() + encrypted.toString();
	return transitmessage;
}

export const generateSessionId = async (chatId:any, chatName:string) => {
	try {
		const ipResponse = await fetch('https://api.ipify.org?format=json');
		const ipData = await ipResponse.json();
		const {ip} = ipData;
		const userAgent = navigator.userAgent.split(" ").slice(-1)[0];
		const data = `${userAgent}+${ip}`;

		const hash = encrypt(data, chatName);
		const encodedHash = encodeURIComponent(hash);
		const session = `${chatId}+${hash}`;
		return session;
	} catch (error) {
		console.error('Error generating session ID:', error);
		throw error;
	}
}

export const mergeRefs = (refs: any[]) => {
	return (value: any) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(Math.random() * colors.length);

	return colors[color];
};

export const priceFormat = (price: number) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const average = (array: any[]) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1: number, value2: number) =>
	Number(((value1 / value2 - 1) * 100).toFixed(2));

export const getFirstconstter = (text: string, constterCount = 2): string =>
	// @ts-ignore
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, constterCount);

export const debounce = (func: (arg0: any) => void, wait = 1000) => {
	let timeout: string | number | NodeJS.Timeout | undefined;

	return function executedFunction(...args: any[]) {
		const later = () => {
			clearTimeout(timeout);
			// @ts-ignore
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};
