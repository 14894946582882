import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';


const API_ENDPOINT: string = `${process.env.REACT_APP_API_ENDPOINT}`;

const authHeader = (): { Authorization: string } => ({
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    'Content-Type': 'application/json',
  },
});

export default class DataService {
  static async get(path: string = ''): Promise<AxiosResponse> {
    const res = await client({
      method: 'GET',
      url: path,
      headers: { ...authHeader() },
    }).then((response) => {
      return response;
    }).catch((error) => {
      return error;
    });

    if (res.status === 401 || res.status === 403 || res.status === 422) {
      localStorage.setItem('access_token', {} as any);
      window.location.href = '/auth-pages/login';
    }

    return res;
  }

  static post(path: string = '', data: {} = {}, optionalHeader: {} = {}): Promise<AxiosResponse> {
    if (optionalHeader === null) {
      optionalHeader = {'Content-Type': 'application/json'};
    }

    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static patch(path: string = '', data: {} = {}): Promise<AxiosResponse> {
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path: string = '', data: {} = {}): Promise<AxiosResponse> {
    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static delete(path: string = ''): Promise<AxiosResponse> {
    return client({
      method: 'DELETE',
      url: path,
      headers: { ...authHeader() },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`
  
    return config;
  }, function (error): Promise<any> {
    return Promise.reject(error);
  });
  
  client.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => response,
    (error): Promise<any> => {
      if (error) {
        console.log(error)
        if (error.status === 401 || error.status === 403 || error.status === 422) {
          localStorage.setItem('access_token', {} as any);
          // redirect
          window.location.href = '/';
        }
        const { response } = error;
        return Promise.reject(response);
      }
      return Promise.reject(error);
    },
  );


