import React, { SVGProps } from 'react';

const SvgCustomBrazil = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 512 512'
    xmlSpace='preserve'
    width='1em'
    height='1em'
    className='svg-icon'
    {...props} >
    <mask
			id='custom-brazil_svg__a'
			style={{
				maskType: 'alpha',
			}}
			maskUnits='userSpaceOnUse'
			x={0}
			y={0}
			width={512}
			height={512}>
			<path
				d='M400 0H112C50.144 0 0 50.144 0 112v288c0 61.856 50.144 112 112 112h288c61.856 0 112-50.144 112-112V112C512 50.144 461.856 0 400 0Z'
				fill='#036738'
			/>
		</mask>
    <g mask='url(#custom-brazil_svg__a)'>
      {/* Green background */}
      <rect x='0' y='0' width='512' height='512' fill='#009B3A' />

      {/* Yellow diamond */}
      <polygon points='256,65 480,256 256,447 32,256' fill='#FFCC29' />

      {/* Blue globe */}
      <circle cx='256' cy='256' r='90' fill='#003399' />

      {/* Stars */}
      <g fill='#FFCC29'>
        <path d='M256 196.9l7.3 22.3h23.4l-18.9 13.8 7.3 22.3-18.9-13.8-18.9 13.8 7.3-22.3-18.9-13.8h23.4zM297.8 222.2l7.3 22.3h23.4l-18.9 13.8 7.3 22.3-18.9-13.8-18.9 13.8 7.3-22.3-18.9-13.8h23.4zM255.1 245.8l7.3 22.3h23.4l-18.9 13.8 7.3 22.3-18.9-13.8-18.9 13.8 7.3-22.3-18.9-13.8h23.4zM214.5 222.2l7.3 22.3h23.4l-18.9 13.8 7.3 22.3-18.9-13.8-18.9 13.8 7.3-22.3-18.9-13.8h23.4zM176.2 196.9l7.3 22.3h23.4l-18.9 13.8 7.3 22.3-18.9-13.8-18.9 13.8 7.3-22.3-18.9-13.8h23.4zM235.8 276.2l7.3 22.3h23.4l-18.9 13.8 7.3 22.3-18.9-13.8-18.9 13.8 7.3-22.3-18.9-13.8h23.4z' />
      </g>
    </g>
  </svg>
);

export default SvgCustomBrazil;
