import React, { SVGProps } from 'react';

const SvgCustomSpain = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 512 512'
    xmlSpace='preserve'
    width='1em'
    height='1em'
    className='svg-icon'
    {...props} >

    <mask
			id='custom-spain_svg__a'
			style={{
				maskType: 'alpha',
			}}
			maskUnits='userSpaceOnUse'
			x={0}
			y={0}
			width={512}
			height={512}>
			<path
				d='M400 0H112C50.144 0 0 50.144 0 112v288c0 61.856 50.144 112 112 112h288c61.856 0 112-50.144 112-112V112C512 50.144 461.856 0 400 0Z'
				fill='#036738'
			/>
		</mask>
    <g mask='url(#custom-spain_svg__a)'>
      {/* Red band at the top */}
      <rect x='0' y='0' width='512' height='171' fill='#C60C30' />

      {/* Yellow band in the middle */}
      <rect x='0' y='171' width='512' height='170' fill='#FFCC00' />

      {/* Red band at the bottom */}
      <rect x='0' y='341' width='512' height='171' fill='#C60C30' />

      {/* Coat of Arms - You can add the detailed path of the coat of arms here */}
      <text x='50' y='256' fontSize='48' fontWeight='bold' fill='#000'>CoA</text>
    </g>
  </svg>
);

export default SvgCustomSpain;
