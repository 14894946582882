import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../../../layout/Footer/Footer';
import Popovers from '../../../components/bootstrap/Popovers';

const DefaultFooter = () => {
	const { t } = useTranslation(['translation', 'menu']);
	return (
		<Footer>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col'>
						<Popovers
							title={t('Negócios mais inteligentes com IA')??'Negócios mais inteligentes com IA'}
							desc={<code><Link to='https://douro.ai'>https://douro.ai</Link></code>}>
							{t('Douro.ai')??'Douro.ai'}
						</Popovers>
						<code className='ps-3'>{t('Administrative Panel')}</code>
					</div>
					<div className='col-auto'>
						<Popovers
							title={t('All rights reserved')??'All rights reserved'}
							desc={<code>v.0.3.8</code>}>
							@ 2023
						</Popovers>
						<code className='ps-3'>{t('All rights reserved')}</code>
					</div>
				</div>
			</div>
		</Footer>
	);
};

export default DefaultFooter;
